<template>
    <div class="create-company">
        <div class="create-company__wrapper container">
            <!--            <InternalHeader :title="title" class="create-company__header"/>-->
            <VBreadcrumbs :customRouteIndex="1" :customRouteLink="`/companies/${route.params.id}`"
                class="create-company__breadcrumbs" />
            <h1 class="create-company__title main-title">{{ title }}</h1>

            <MainBlock class="create-company__block" v-model="form" :validation="validation" :errorText="errorFields" />
            <PersonalDataProcessing class="create-company__personal-data-processing" v-model="form.privacy_policy_file"
                :errorText="fileErrorText" :isError="!!fileErrorText" @change="onFileChange" />
            <SettingsBlock class="create-company__settings" v-model="form" />
            <TreatyBlock class="create-company__treaty" v-model="form" :errorText="dateErrorText"
                v-if="!editing || (editing && form.id)" :validation="validation" />

            <div class="create-company__btns">
                <VButton label="Добавить" :disabled="disabled" @click="submit" v-if="!editing"
                    class="create-company__btn create-company__btn_add" />

                <VButton label="Сохранить" :disabled="disabled" @click="submit" v-else
                    class="create-company__btn create-company__btn_add" />

                <VButton label="Отменить" class="create-company__btn create-company__btn_cancel" bg="#E4E7EE"
                    color="#1E3959" bgHover="#BBC4CD" colorHover="#1E3959" @click="showPopup = true" />
            </div>
        </div>

        <ModalWindow v-if="showPopup" @close="showPopup = false" class="create-company__modal">
            <CancelChanges @close="showPopup = false" @cancelChanges="router.push('/companies')" />
        </ModalWindow>

        <ModalWindow
            v-if="showPopupCloseContract && !showContractSlosureConfirmation && (hasContract && !form.hasContract)"
            @close="showPopupCloseContract = false" class="create-company__modal-close-contract">
            <CloseContract @close="showPopupCloseContract = false" @goToClosing="goToClosing" />
        </ModalWindow>


        <ModalWindow v-if="showContractSlosureConfirmation" @close="closeContractSlosureConfirmation"
            class="create-company__modal-close-contract">
            <ContractSlosureConfirmation :errorText="contractSlosureConfirmationError"
                @close="closeContractSlosureConfirmation" @closeContract="contractSlosureConfirmation" />
        </ModalWindow>
    </div>
</template>

<script setup>
import { onMounted, computed, ref, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import moment from "moment";
import companies from "@/api/companies";
import useOffsetTop from "@/hooks/getOffsetTopScroll";

import CancelChanges from '@/components/ModalWindow/ModalBodyes/CancelChanges'
import CloseContract from '@/components/ModalWindow/ModalBodyes/CloseContract'
import ContractSlosureConfirmation from '@/components/ModalWindow/ModalBodyes/ContractSlosureConfirmation'
import InternalHeader from "@/components/InternalHeader";
import MainBlock from "@/components/CreateCompany/MainBlock";
import PersonalDataProcessing from "@/components/CreateCompany/PersonalDataProcessing";
"C:\Users\fort2\PDD\vahta-frontend\src\components\CreateEmployeeAndAdmin\PersonalDataProcessing"
import SettingsBlock from "@/components/CreateCompany/SettingsBlock";
import TreatyBlock from "@/components/CreateCompany/TreatyBlock";

const router = useRouter();
const route = useRoute();

const baseUrl = process.env.VUE_APP_BASE;
const editing = !!route.params.id
const title = editing ? 'Редактировать компанию' : 'Новая компания'
const fileErrorText = ref('')
const form = reactive({
    logo: '',
    name: '',
    inn: '',
    ogrn: '',
    description: '<p>О компании:</p><p>___</p><p> </p><p>Преимущества:</p><p>___</p><p>___</p>',
    verificationOfLoyalty: false,
    chatsForApprovingPerson: false,
    extendedQuestionnaire: false,
    activationTicketsStage: false,
    hasContract: true,
    date: {
        start: null,
        end: null,
    },
    privacy_policy_file: null
})

const showPopup = ref(false)
const showPopupCloseContract = ref(false)
const showContractSlosureConfirmation = ref(false)
const contractSlosureConfirmationError = ref('')

const companyNameConfirmation = ref('')

const rejectionReason = `Благодарим за ваш отклик. Мы внимательно ознакомились с вашим резюме. К сожалению, на данном этапе мы не готовы предложить вам работу. Искренне желаем удачи в поиск`

const disabled = ref(false)

const requiredContract = (value) => {
    if (form.hasContract) {
        return !!value
    } else {
        return true
    }
}

const rules = computed(() => ({
    name: { required },
    inn: { required },
    ogrn: { required },
    description: { required },
    date: {
        start: { requiredContract },
        end: { requiredContract },
    }
}));
const v$ = useVuelidate(rules, form);
const validation = ref(false)

const hasContract = ref(false)

const dateErrorText = ref('')

const onFileChange = (file) => {
    if (file) fileErrorText.value = '';
}

const getCompany = async () => {
    try {
        const response = await companies.getCompany(route.params.id);
        hasContract.value = response.has_contract
        form.id = response.id
        form.logo = response.logo ? baseUrl + response.logo : ''
        form.name = response.name
        form.inn = response.inn
        form.ogrn = response.ogrn
        form.description = response.description
        form.extendedQuestionnaire = !!response.access_extended_form
        form.verificationOfLoyalty = !!response.verification_of_loyalty
        form.chatsForApprovingPerson = !!response.chats_for_approving_person
        form.activationTicketsStage = !!response.activation_tickets_stage
        form.hasContract = !!response.has_contract
        form.date.start = response.contract_date_from ? new Date(response.contract_date_from) : ''
        form.date.end = response.contract_date_to ? new Date(response.contract_date_to) : ''
        form.startFormat = moment(response.contract_date_from).format('DD/MM/YYYY')
        form.endFormat = moment(response.contract_date_to).format('DD/MM/YYYY')
        form.privacy_policy_file = response?.privacy_policy_file || response?.privacy_policy
    } catch (error) {
        console.log(error);
    }
}
const errorFields = ref(null)
const companyRequest = async () => {
    const validate = await v$.value.$validate();
    if (!validate) {
        validation.value = true
        setTimeout(() => {
            useOffsetTop()
        }, 0)
        return
    }
    validation.value = false
    disabled.value = true

    const body = {
        name: form.name,
        inn: form.inn,
        ogrn: form.ogrn,
        description: form.description,
        access_extended_form: form.extendedQuestionnaire,
        verification_of_loyalty: form.verificationOfLoyalty,
        chats_for_approving_person: form.chatsForApprovingPerson,
        activation_tickets_stage: form.activationTicketsStage,
        has_contract: form.hasContract,
        ...(form.date.start && {contract_date_from: form.date.start.toJSON()}),
        ...(form.date.end && {contract_date_to: form.date.end.toJSON()}),
        privacy_policy_file: form.privacy_policy_file
    }

    if (form.logo[0] === 'd') body.logo = form.logo

    const formData = new FormData();

    try {
        if (!editing) {
            for (const key in body) {
                if (key === 'privacy_policy_file') {
                    if (typeof body['privacy_policy_file'] !== 'string') {
                        formData.append(key, body[key])
                    } else {
                        continue
                    }
                }
                if(typeof body[key] === 'boolean'){
                    formData.append(key, Number(body[key]))
                }else {
                formData.append(key, body[key])

                }
            }
            await companies.postCompany(formData)
        } else {

            body.company_name = companyNameConfirmation.value
            body.rejection_reason = rejectionReason

            for (const key in body) {
                if (key === 'privacy_policy_file') {
                    if (typeof body['privacy_policy_file'] !== 'string') {
                        formData.append(key, body[key])
                    } else {
                        continue
                    }
                }
                if(typeof body[key] === 'boolean'){
                    formData.append(key, Number(body[key]))
                }else {
                formData.append(key, body[key])

                }
            }

            const response = await companies.putCompany(route.params.id, formData)
            showContractSlosureConfirmation.value = false
        }
        errorFields.value = null
        dateErrorText.value = ''
        await router.push("/companies");
    } catch (error) {
        console.log(error)
        disabled.value = false
        errorFields.value = null
        dateErrorText.value = ''



        if (error.response.data.errors.contract_date_to) {
            dateErrorText.value = error?.response?.data?.errors?.contract_date_to[0]
        }

        if (error.response.data.errors.contract_date_end) {
            dateErrorText.value = error?.response?.data?.errors?.contract_date_end[0]
        }

        if (error.response.data.errors.inn || error.response.data.errors.ogrn) {
            errorFields.value = error.response.data.errors
        }

        if (error.response.data.errors && error.response.data.errors.company_name) {
            contractSlosureConfirmationError.value = error.response.data.errors.company_name[0]
        }
    }
}

const submit = () => {
    if (hasContract.value && !form.hasContract) {
        showPopupCloseContract.value = true
        return
    }

    if (!form.privacy_policy_file) {
        fileErrorText.value = 'Загрузите файл'
        return
    } else {
        fileErrorText.value = ''
    }

    companyRequest()
}

const goToClosing = () => {
    showPopupCloseContract.value = false
    showContractSlosureConfirmation.value = true
}

const closeContractSlosureConfirmation = () => {
    showContractSlosureConfirmation.value = false
    contractSlosureConfirmationError.value = ''
}

const contractSlosureConfirmation = (name) => {
    companyNameConfirmation.value = name
    companyRequest()
}

onMounted(() => {
    if (editing) getCompany()
})
</script>

<style scoped lang="scss">
.create-company {
    padding-bottom: 160px;

    &__title {
        margin-top: 12px;
    }

    &__wrapper {
        padding-top: 12px;
    }

    &__block {
        margin-top: 24px;
    }

    &__personal-data-processing {
        margin-top: 24px;
    }

    &__settings {
        margin-top: 24px;
    }

    &__treaty {
        margin-top: 24px;
    }

    &__btns {
        margin-top: 76px;
        display: flex;
        justify-content: space-between;
    }

    &__btn {

        &_cancel {
            width: 42.3079%;

            &:active {
                background-color: $gray !important;
            }
        }

        &_add {
            width: 53.846153%;

            &:active {
                background-color: #006894 !important;
            }
        }
    }

    &__modal {
        &::v-deep(.modal__body) {
            width: 304px;
            border-radius: 16px;
        }
    }

    &__modal-close-contract {
        &::v-deep(.modal__body) {
            width: 304px;
        }
    }
}
</style>
