<template>
    <div class="settings">
        <h3 class="settings__title">Настройки</h3>
        <div class="settings__content">
            <div class="settings__settings">
                <div class="settings__setting">
                    <p class="settings__setting-label">Расширенная анкета</p>
                    <VCheckbox v-model="form.extendedQuestionnaire"/>
                </div>
<!--                <div class="settings__setting">-->
<!--                    <p class="settings__setting-label">Проверка на благонадёжность</p>-->
<!--                    <VCheckbox v-model="form.verificationOfLoyalty"/>-->
<!--                </div>-->
                <div class="settings__setting">
                    <p class="settings__setting-label">Чаты</p>
                    <VCheckbox v-model="form.chatsForApprovingPerson"/>
                </div>
                <div class="settings__setting">
                    <p class="settings__setting-label">Этап Билеты</p>
                    <VCheckbox v-model="form.activationTicketsStage"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, computed} from 'vue'

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
});

const emit = defineEmits(["update:modelValue"]);

const form = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
});

const a = ref(false)
</script>

<style scoped lang="scss">
.settings {
    width: 100%;
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0px #0000000A;


    &__title {
        font-size: 20px;
        font-weight: 500;
    }

    &__content {
        margin-top: 16px;
    }

    &__setting {
        width: 100%;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    &__setting-label {
        color: $dark;
        font-size: 14px;
        font-weight: 400;
    }

    &__row {
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
    }

    &__row &__checkbox-label {
        width: 49%;
    }

    &__checkbox-label {
        width: 100%;
    }
}
</style>
